interface Config {
    apiUrl: string;
}
  
const developmentConfig: Config = {
    // apiUrl: 'http://127.0.0.1:8000',
    apiUrl: 'https://makosftp.pythonanywhere.com',
};
  
const productionConfig: Config = {
    // apiUrl: 'http://127.0.0.1:8000',
    apiUrl: 'https://makosftp.pythonanywhere.com',
};
  
const config: Config = process.env.REACT_APP_API_URL === 'development' ? developmentConfig : productionConfig;
  

export default config;
  