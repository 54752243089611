import { LockOutlined } from "@mui/icons-material";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import logo from '../makorxLogo.png';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {};

  return (
    <>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 'auto',
          }}
        >
          <Box
            component="img"
            sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                marginTop: 'auto',
            }}
            alt="MakoRx Logo"
            src={logo}
            />
            
          <Typography variant="h5">Reset Password</Typography>

          <Box sx={{ mt: 1,
                    width: {
                        xs: '100%', 
                        sm: 300, 
                        md: 400, 
                        lg: 500, 
                        xl: 600, 
                    },
                }}
            >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            /> */}

            <Typography sx={{ fontSize: '.75rem' }}>
                <Link to="/register" style={{ color: '#397B96' }}>
                Forgot Password?
                </Link>
            </Typography>

            <Button
              
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2, backgroundColor: '#397B96', color: 'white', '&:hover': { backgroundColor: '#0097a7' } }}
              onClick={handleLogin}
            >
              Submit
            </Button>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <Link to="/login" style={{ fontSize: '1rem' ,color: '#397B96' }}>Go back to login</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Login;