import { createContext, useState } from 'react';

type Bucket = {
  name: string;
  id: number;
};

type PartnerProfile = {
    // id: number;
    // contact_number: string;
    // role: string;
    // other: string;
    // partner: number
    id: number;
    name: string;
    phone_number: string;
    address1: string;
    address2: string | null; 
    bucket: Bucket[];
  }

type User = {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    partner: PartnerProfile
};


// type UserWithoutPassword = Omit<User, 'password'>;

// interface UserContext {
//     user: User | null;
//     login: (user: User) => void;
//     logout: () => void;
//   }

//   const UserContext = createContext<UserContext>({
//     user: null,
//     login: () => {},
//     logout: () => {},
//   });

// const UserProvider = ({ children }: any) => {
//     const [user, setUser] = useState<User | null>(localStorage.getItem('user')? JSON.parse(localStorage.getItem('user') || '') : null);
//     const login = (userData: User) => {
//         const saveData = {
//             id: userData.id,
//             username: userData.username,
//             first_name: userData.first_name,
//             last_name: userData.last_name,
//             email: userData.email,
//             profile: userData.partner,
//         };
//         localStorage.setItem('user', JSON.stringify(saveData));
//         setUser(userData);
//     };
//     const logout = () => {
//         localStorage.removeItem('user');
//         setUser(null);
//     }; 

//   return (
//     <UserContext.Provider value={{ user, login, logout }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// export { UserProvider, UserContext };

interface UserContext {
  user: User | null;
  token: string | null;
  login: (user: User, token: string) => void;
  logout: () => void;
}

const UserContext = createContext<UserContext>({
  user: null,
  token: null,
  login: () => {},
  logout: () => {},
});

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(
    localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null
  );
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token') || null
  );

  const login = (userData: User, userToken: string) => {
    const saveData = {
      id: userData.id,
      username: userData.username,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      profile: userData.partner,
    };
    localStorage.setItem('user', JSON.stringify(saveData));
    localStorage.setItem('token', userToken);
    setUser(userData);
    setToken(userToken);
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
    setToken(null);
  };

  return (
    <UserContext.Provider value={{ user, token, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
