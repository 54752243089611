import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Button, Grid, CssBaseline, Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import './dropzone.css';
import Navbar from "./navbar";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LinearBuffer from './linearBuffer';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { UserContext } from '../../userContext';

interface DropzoneProps {
  //onFileUpload: (file: File) => void;
  folderId: number
  partnerId: number,
  bucketId: number,
  userId: number
}
interface FormValues {
  file: File | null;
}

const apiUrl = config.apiUrl;

const DropZone = ({partnerId, folderId, bucketId, userId} : DropzoneProps) => {
  const initialValues: FormValues = { file: null };
  const navigate = useNavigate();
  const [message, setMessage] = useState({ type: '', content: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const { user,token } = useContext(UserContext);

// console.log(partnerId);
// console.log(bucketId)
// console.log(userId)

  const validationSchema = Yup.object().shape({
      file: Yup.mixed()
       .required("A file is required")
       .test(
              "fileType",
              "Only text files are allowed",
              (value) => 
                value instanceof File && 
                [
                    'application/vnd.ms-excel',      // .xls
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                    'text/csv'   ,                    // .csv
                    'text/plain' // .txt
                ].includes(value.type)        
              //value => value instanceof File
          )
       .test(
              "fileName",
              "File name format should be ELIG_PARTNERNAME_yyyymmdd",
              //value => value instanceof File && /^ELIG_([A-Za-z0-9]+)_\d{4}\d{2}\d{2}\$/i.test(value.name)
              value => value instanceof File && /^ELIG_([A-Za-z0-9]+)_\d{4}\d{2}\d{2}\.(txt|csv|xls|xlsx)$/i.test(value.name)

          )
  });

  const formik = useFormik({
      initialValues: {
          file: null
      },
      validationSchema,
      onSubmit: async (values) => {
          if (values.file) {
              setLoading(true);
              const formData = new FormData();
              formData.append("file", values.file);
              const filename = (values.file as File)?.name; 
              const partner = partnerId.toString();
              const folder = folderId.toString();
              const bucket = bucketId.toString();
              const user = userId.toString();
              formData.append("partner", partner);
              formData.append("folder", folder);
              formData.append("bucket", bucket);
              formData.append("user", user);
              // formData.append("filename", values.file);
              formData.append("name", filename);

              try {
                  const response = await axios.post(`${apiUrl}/api/file/check_file_exists/`, formData, {
                      headers: {
                          'Authorization': `Token ${token}`,
                          'Content-Type': 'multipart/form-data'
                      }
                  });
                if (response.data.exists === false) {
                  const response = await axios.post(`${apiUrl}/api/file/`, formData, {
                    headers: {
                      'Authorization': `Token ${token}`,
                      'Content-Type': 'multipart/form-data'
                    }
                  });
                   if (response.status === 201) {
                      setMessage({ type: 'success', content: 'File upload successful!' });
                      // setTimeout(() => navigate('/home'), 2000); 
                      setTimeout(() => {
                          //navigate('/files');
                          setLoading(false); 
                          setMessage({ type: '', content: '' });
                      }, 2000);
                   } 
                
                } else {

                  let suffix = 'A';
                  let newFilename = '';

                  const newFormData = new FormData(); 
                  newFormData.append("partner", partner);
                  newFormData.append("folder", folder);
                  newFormData.append("bucket", bucket);
                  newFormData.append("user", user);
                  
                  while (true) { 
                    const [baseName, extension] = filename.split('.');
                    newFilename = `${baseName}_${suffix}.${extension}`;

                    const newFile = new File([values.file], newFilename);
                    const newFName = new File([values.file], newFilename);
                    newFormData.append("file", newFile); 
                    newFormData.append("filename", newFName); 
                    newFormData.append("name", newFilename);

                    try {
                      const existsResponse = await axios.post(`${apiUrl}/api/file/check_file_exists/`, newFormData, {
                        headers: {
                          'Authorization': `Token ${token}`,
                          'Content-Type': 'multipart/form-data'
                        }
                      });
                      if (!existsResponse.data.exists) {
                        break; 
                      }
                    } catch (error) {
                      console.error("Error checking file existence:", error);
                      setMessage({ type: 'error', content: 'An error occurred while checking file existence.' });
                      return; 
                    }

                    suffix = String.fromCharCode(suffix.charCodeAt(0) + 1);
                    if (suffix > 'Z') {
                      if (suffix.length === 1) {
                        suffix = 'AA';
                      } else {
                        suffix = 'A' + suffix.slice(1);
                      }
                    }
                  }
                  
                  const retryResponse = await axios.post(`${apiUrl}/api/file/`, newFormData, {
                    headers: {
                       'Authorization': `Token ${token}`,
                      'Content-Type': 'multipart/form-data'
                    }
                  });
                
                  if (retryResponse.status === 201) {
                    setMessage({ type: 'success', content: 'File upload successful!' });
                    
                  } else {
                    setMessage({ type: 'error', content: 'File upload failed!' });
                    console.error('File upload failed:', retryResponse.data);
                  }
                }
                
                  // const response = await axios.post(`${apiUrl}/api/file/`, formData, {
                  //     headers: {
                  //         'Content-Type': 'multipart/form-data'
                  //     }
                  // });
                  // const response = await axios.post(`${apiUrl}/api/file/check_file_exists/`, formData, {
                  //     headers: {
                  //         'Content-Type': 'multipart/form-data'
                  //     }
                  // });
                  // console.log(values.file)
                  // console.log(response.data.exists)
                  // console.log(filename.split("."))
                  // if(response.data.exists === false){
                    
                  //   const response = await axios.post(`${apiUrl}/api/file/`, formData, {
                  //     headers: {
                  //         'Content-Type': 'multipart/form-data'
                  //     }
                  //   });
                  //   if (response.status === 201) {
                  //     setMessage({ type: 'success', content: 'File upload successful!' });
                  //     console.log('File upload successful:', response.data);
                  //     // setTimeout(() => navigate('/home'), 2000); 
                  //     setTimeout(() => {
                  //         //navigate('/files');
                  //         setLoading(false); 
                  //         setMessage({ type: '', content: '' });
                  //     }, 2000);
                  //  } 
                  // }
                  //else{
                  
                  //   console.log(filename.split("."))
                  //   const suffix = 'A';
                  //   const newFilename = `${filename.split('.')[0]}_${suffix}.${values.file.name.split('.')[1]}`;
                  //     suffix = String.fromCharCode(suffix.charCodeAt(0) + 1);
                  //     if (suffix > 'Z') {
                  //       suffix = 'AA';
                  //     }
                  //   }
                  // }
                 
              } catch (error) {
                  console.error('File upload failed:', error);
                  setMessage({ type: 'error', content: 'File upload failed. Please try again.' });
                  setLoading(false); 
              }
          } else {
              setMessage({ type: 'error', content: 'No file selected. Please select a file to upload.' });
          }
          setLoading(false); 
      }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/plain': [],
      'application/vnd.ms-excel': [],           
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/csv': []                           
    },
    onDrop: (acceptedFiles) => {
      formik.setFieldValue('file', acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (file) {
      formik.setFieldValue('file', file);
    }
  }, [file]);

  const handleSubmit = (e:any) => { 
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <>
    <CssBaseline />
    <Grid container spacing={2}>
      <Grid item xs={12}>
         {/* <Grid container spacing={2} sx={{ marginTop:'auto'}}>
         <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              sx={{ marginLeft: "auto", backgroundColor: '#397B96', color: 'white', '&:hover': { backgroundColor: '#0097a7' } }}
              >
            Upload file
          </Button>
        </Grid> */}
        <Grid container {...getRootProps()} className='dropzone' direction="column"> 
          <input {...getInputProps()} />
          {isDragActive? (
            <p>Drop the file here...</p>
          ) : (
            <p>Drag and drop a text file or click to select a file</p>
          )}
          {file && (
            <div style={{ marginTop: '10px' }}>
              <InsertDriveFileIcon></InsertDriveFileIcon>
              <p>{file.name}</p>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div>
          {formik.errors.file && (
            // <Typography style={{ padding:5, fontSize: '1rem',color: 'red'}}>
            //   {formik.errors.file}
            // </Typography>
            <Alert icon={<PriorityHighIcon fontSize="inherit" />} severity='error'> 
            {formik.errors.file}
          </Alert>
          )}
          {message.content && (
            // <Box sx={{marginTop:2,
            //           color: message.type === 'success' ? 'green' : 'red'
            //         }}>
            //         {message.content}
            // </Box>
             <Alert icon={<CheckIcon fontSize="inherit" />} severity={message.type === 'success' ? 'success' : 'error'}> 
             {message.content}
           </Alert>
          )}
          {loading? (
            <LinearBuffer />
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting || !formik.isValid}
              sx={{ marginTop:2,backgroundColor: '#397B96', color: 'white', }}
              onClick={handleSubmit}
            >
              Upload
            </Button>
          )}
          
        </div>
      </Grid>
    </Grid>
    
    </>
  );
};

export default DropZone;