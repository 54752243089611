import React from 'react';
import logo from './logo.svg';
import {Routes, Route} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import FileDisplay from "./pages/FileUpload/components/files";
import FolderDisplay from "./pages/FileUpload/components/folders";
import FileUploadComponent from "./pages/FileUpload/components/dropzone";
import { BrowserRouter as Router} from 'react-router-dom';

function App() {
  return (
      <>
       <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/home" element={<FolderDisplay />} />
        <Route path="/files" element={<FileDisplay />} />
       </Routes>
      </>
  );
}

export default App;
