import { LockOutlined } from "@mui/icons-material";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import logo from '../makorxLogo.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import config from '../config';

interface FormValues {
  partnerName: string;
  username: string;
  email: string;
}

const validationSchema = Yup.object({
  partnerName: Yup.string().required('Partner Name is required'),
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const apiUrl = config.apiUrl;

const Register = () => {
  const [partnerName, setPartnerName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      partnerName: '',
      username: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/api/register', values);
        if (response.status === 200) {
          console.log('Registration successful:', response.data);
          navigate('/login');
        }
      } catch (error) {
        console.error('Registration failed:', error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
              component="img"
              sx={{
                  height: 233,
                  width: 350,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  marginTop: 'a uto',
              }}
              alt="MakoRx Logo"
              src={logo}
              />
          <Typography component="h1" variant="h5">MakoRx Partner Registration</Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              fullWidth
              id="partnerName"
              name="partnerName"
              label="Partner Name"
              value={formik.values.partnerName}
              onChange={formik.handleChange}
              error={formik.touched.partnerName && Boolean(formik.errors.partnerName)}
              helperText={formik.touched.partnerName && formik.errors.partnerName}
              margin="normal"
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              margin="normal"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2, backgroundColor: '#397B96', color: 'white', '&:hover': { backgroundColor: '#0097a7' } }}
            >
              Register
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography style={{ fontSize: '1rem' ,color: '#397B96'}}>Already have an account?
                    <Link to="/login" style={{ fontSize: '1rem' ,color: '#397B96', marginLeft:'10px'}}>Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </form>
  );
};

export default Register;