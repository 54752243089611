import logo from '../../../makorxLogo.png';
import React, { useState, useEffect, useContext } from 'react';
import {useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { UserContext } from '../../userContext';


export default function ButtonAppBar() {

  const { user, logout} = useContext(UserContext);
  const navigate = useNavigate();

  const logoutClick = () => {
    logout();
    navigate('/login/'); 
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default" sx={{width:'100%', margin:'0', backgroundColor: '#fff', padding:'0'}}>
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          > */}
            <img src={logo} alt="Logo" style={{ maxHeight: '60px', marginRight: '20px' }}/>
          {/* </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MakoRx Partner Eligibility 
          </Typography>
          <Button color="inherit" onClick={logoutClick}>Logout</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}