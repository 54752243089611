import * as React from "react";
import { Button, Box, Grid, Paper, TextField, Container,CssBaseline, Typography, Link, Card} from '@mui/material';
import Modal from "@mui/material/Modal";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import logo from '../../../makorxLogo.png';
import DropZone from "./dropzone";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '80%', 
  maxWidth: '1000px',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  folderId: number,
  partnerId: number,
  bucketId: number,
  userId: number
}


const UploadModal = ({ open, onClose, folderId, partnerId, bucketId, userId} : UploadModalProps) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <IconButton 
           aria-label="close" 
           onClick={onClose} 
           sx={{ position: 'absolute', right: 8, top: 8 }}
         >
           <CloseIcon />
         </IconButton>
         
         <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ maxHeight: '60px', marginRight: '20px' }}/>
          Upload Eligibility File
        </Typography>
        <Grid container sx={{ marginTop: '2%', justifyContent: 'flex-end', magrginLeft:'auto' }}>
          <DropZone partnerId={partnerId} folderId={folderId} bucketId={bucketId} userId={userId}/>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UploadModal;